import React, { useState } from 'react';
import axios from '../../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import styles from '../FormElements.module.css';

function Login() {
  const [formData, setFormData] = useState({
    login: '',
    password: ''
  });
  const [error, setError] = useState('');
	const navigate = useNavigate();

  const { login, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/users/login', formData);
      localStorage.setItem('token', res.data.token);
			localStorage.setItem('user', JSON.stringify({
      	username: res.data.username,
      	role: res.data.role  // Assuming the backend sends the role
    	}));
			//navigate('/home');
			navigate(-1);
    } catch (err) {
      setError(err.response.data.msg || 'An error occurred');
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Login</h2>
      <form onSubmit={onSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="login" className={styles.formLabel}>Username or Email</label>
          <input
            type="text"
            id="login"
            name="login"
            value={login}
            onChange={onChange}
            required
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password" className={styles.formLabel}>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={onChange}
            required
            className={styles.formInput}
          />
        </div>
        {error && <p className={styles.formError}>{error}</p>}
        <button type="submit" className={styles.authButton}>Login</button>
      </form>
    </div>
  );
}

export default Login;
