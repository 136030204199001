import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/site/Layout';
import Home from './components/Home';
import ReactGA from 'react-ga4';

import Register from './components/user/Register';
import VerifyEmail from './components/user/VerifyEmail';
import Login from './components/user/Login';
import Profile from './components/user/Profile';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';

import ProtectedArchitectRoute from './components/site/ProtectedArchitectRoute';
import ArchitectDashboard from './components/architect/ArchitectDashboard';
import CategoryManagement from './components/architect/CategoryManagement';
import UserManagement from './components/architect/UserManagement';
import QuizManagement from './components/architect/QuizManagement';
import QuizForm from './components/architect/QuizForm';

import QuizList from './components/quiz/QuizList';
import QuizDetail from './components/quiz/QuizDetail';

function App() {
	ReactGA.initialize("G-M9BD42JCSM");

  return (
    <Router>
      <Layout>
      	<Routes>
          <Route exact path="/" element={<Home />} />
    			<Route path="/quizzes" element={<QuizList />} />
          <Route path="/quiz/:slug" element={<QuizDetail />} />
          <Route path="/register" element={<Register />} />
					<Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password/:token" element={<ResetPassword />} />
					<Route path="/architect" element={<ProtectedArchitectRoute><ArchitectDashboard /></ProtectedArchitectRoute>} />
					<Route path="/architect/users" element={<ProtectedArchitectRoute><UserManagement /></ProtectedArchitectRoute>} />
					<Route path="/architect/quizzes" element={<ProtectedArchitectRoute><QuizManagement /></ProtectedArchitectRoute>} />
					<Route path="/architect/quizzes/create" element={<ProtectedArchitectRoute><QuizForm /></ProtectedArchitectRoute>} />
					<Route path="/architect/quizzes/edit/:id" element={<ProtectedArchitectRoute><QuizForm /></ProtectedArchitectRoute>} />
		      <Route path="/architect/categories" element={<ProtectedArchitectRoute><CategoryManagement /></ProtectedArchitectRoute>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
