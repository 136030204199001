import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axiosConfig';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../FormElements.module.css';

function QuizDetail() {
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [started, setStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [quizEnded, setQuizEnded] = useState(false);
  const { slug } = useParams();
  const [score, setScore] = useState(0);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`/api/quizzes/${slug}`);
        setQuiz(res.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching quiz:', err);
        setError('Failed to load quiz. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [slug]);

	const getCategoryPath = (category) => {
    const path = [];
    let currentCategory = category;
    while (currentCategory) {
      path.unshift(currentCategory);
      currentCategory = currentCategory.parent;
    }
    return path;
  };

  const calculateScore = useCallback(() => {
  	let calculatedScore = 0;
  	quiz.questions.forEach((question, index) => {
    	if (question.questionType === 'MCQ' || question.questionType === 'TrueFalse') {
      	if (question.answers[userAnswers[index]]?.isCorrect) calculatedScore++;
    	} else if (question.questionType === 'ShortAnswer') {
      	if (userAnswers[index]?.toLowerCase() === question.correctAnswer.toLowerCase()) calculatedScore++;
    	} else if (question.questionType === 'MultipleTextAnswer') {
      	if (checkMultipleTextAnswer(userAnswers[index], question.correctAnswers, question.caseSensitive)) calculatedScore++;
    	}
  	});
  	return calculatedScore;
	}, [quiz, userAnswers]);

	const submitQuiz = useCallback(async () => {
  	const finalScore = calculateScore();
  	setScore(finalScore);

  	const token = localStorage.getItem('token');
  	if (token) {
    	try {
      	const response = await axios.post('/api/quizzes/complete', {
        	quizId: quiz._id,
        	score: finalScore
      	});
      	console.log(response.data);
      	// You might want to show a success message to the user here
    	} catch (error) {
      	console.error('Error submitting quiz:', error);
      	// You might want to show an error message to the user here
    	}
  	} else {
    	console.log('Guest user completed quiz with score:', finalScore);
    	// You might want to show a message to the guest user here
  	}
	}, [quiz, calculateScore]);

  const endQuiz = useCallback(() => {
  	setQuizEnded(true);
  	setStarted(false);
  	submitQuiz();
	}, [submitQuiz]);

  useEffect(() => {
    let timer;
    if (started && timeLeft > 0 && !quizEnded) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            endQuiz();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [started, timeLeft, quizEnded, endQuiz]);

  const startQuiz = () => {
    setStarted(true);
    setUserAnswers(new Array(quiz.questions.length).fill(null));
  };

  const handleAnswerSelect = (questionIndex, answer) => {
    setUserAnswers(prevAnswers => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = answer;
      return newAnswers;
    });
  };

  const moveToQuestion = (index) => {
    if (index >= 0 && index < quiz.questions.length) {
      setCurrentQuestion(index);
    }
  };

  const renderQuestion = (question, index) => {
    switch (question.questionType) {
      case 'MCQ':
        return (
          <div>
            <p>{question.questionText}</p>
            {question.answers.map((answer, aIndex) => (
              <button 
                key={aIndex} 
                onClick={() => handleAnswerSelect(index, aIndex)}
                className={userAnswers[index] === aIndex ? styles.selectedAnswer : styles.formButton}
              >
                {answer.answerText}
              </button>
            ))}
          </div>
        );
      case 'TrueFalse':
        return (
          <div>
            <p>{question.questionText}</p>
            <button 
              onClick={() => handleAnswerSelect(index, 0)}
              className={userAnswers[index] === 0 ? styles.selectedAnswer : styles.formButton}
            >
              True
            </button>
            <button 
              onClick={() => handleAnswerSelect(index, 1)}
              className={userAnswers[index] === 1 ? styles.selectedAnswer : styles.formButton}
            >
              False
            </button>
          </div>
        );
      case 'ShortAnswer':
      case 'MultipleTextAnswer':
        return (
          <div>
            <p>{question.questionText}</p>
            <input 
              type="text"
              value={userAnswers[index] || ''}
              onChange={(e) => handleAnswerSelect(index, e.target.value)}
              className={styles.formInput}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const checkMultipleTextAnswer = (userAnswer, correctAnswers, caseSensitive) => {
    if (!userAnswer) return false;
    if (!caseSensitive) {
      userAnswer = userAnswer.toLowerCase();
      return correctAnswers.some(answer => answer.toLowerCase() === userAnswer);
    }
    return correctAnswers.includes(userAnswer);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  if (!quiz) {
    return <div className={styles.error}>Quiz not found.</div>;
  }

  const categoryPath = quiz.category ? getCategoryPath(quiz.category) : [];

  return (
    <div className={styles.quizPage}>
      <Helmet>
        <title>{`${quiz.title} - EL Trivia`}</title>
        <meta name="description" content={quiz.description} />
      </Helmet>

      {/* Breadcrumb */}
      <div className={styles.breadcrumb}>
        <Link to="/">Home</Link>
        {categoryPath.map((cat, index) => {
          // Build the category URL incrementally
          const categoryUrl = `/category/${categoryPath.slice(0, index + 1).map(c => c.slug).join('/')}`;
          return (
            <React.Fragment key={cat._id}>
              {' » '}
              <Link to={categoryUrl}>{cat.name}</Link>
            </React.Fragment>
          );
        })}
        {categoryPath.length > 0 && ' » '}
        <span>{quiz.title}</span>
      </div>

      {/* Quiz Header */}
      <div className={styles.quizHeader} style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${quiz.featuredImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className={styles.quizDetails}>
          <h2>{quiz.title}</h2>
          <p>{quiz.description}</p>
          {categoryPath.length > 0 && (
          	<p>Category: {categoryPath.map(cat => cat.name).join(' > ')}</p>
        	)}
          <p>Time: {quiz.allottedTime} minutes</p>
          <p>Views: {quiz.viewCount}</p>
          <p>Times Taken: {quiz.takenCount}</p>
        </div>
      </div>

      <div className={styles.quizDetails} dangerouslySetInnerHTML={{ __html: quiz.details }} />

    	<div className={styles.quizContent}>
        {!started && !quizEnded && (
          <button onClick={startQuiz} className={styles.formButton}>Start Quiz</button>
        )}

        {started && !quizEnded && (
        	<div>
          	<h3>Question {currentQuestion + 1}</h3>
          	{renderQuestion(quiz.questions[currentQuestion], currentQuestion)}
          	<div className={styles.navigationButtons}>
            	<button onClick={() => moveToQuestion(currentQuestion - 1)} disabled={currentQuestion === 0} className={styles.prevButton}>Previous</button>
            	<button onClick={() => moveToQuestion(currentQuestion + 1)} disabled={currentQuestion === quiz.questions.length - 1} className={styles.nextButton}>Next</button>
          	</div>
          	<button onClick={endQuiz} className={styles.endButton}>End Quiz</button>
        	</div>
      	)}

      	{quizEnded && (
          <div className={styles.quizResults}>
          	<h3>Quiz Results</h3>
          	<p>Your score: {score} out of {quiz.questions.length}</p>
          	{quiz.questions.map((question, qIndex) => (
            	<div key={qIndex}>
              	<p>{question.questionText}</p>
              	{question.questionType === 'MCQ' && (
                	question.answers.map((answer, aIndex) => (
                  	<p key={aIndex} style={{
                    	color: answer.isCorrect ? 'green' : (userAnswers[qIndex] === aIndex ? 'red' : 'black')
                  	}}>
                    	{answer.answerText} {answer.isCorrect && '✓'}
                  	</p>
                	))
              	)}
              	{question.questionType === 'TrueFalse' && (
                	<>
                  	<p style={{ color: question.answers[0].isCorrect ? 'green' : (userAnswers[qIndex] === 0 ? 'red' : 'black') }}>
                    	True {question.answers[0].isCorrect && '✓'}
                  	</p>
                  	<p style={{ color: question.answers[1].isCorrect ? 'green' : (userAnswers[qIndex] === 1 ? 'red' : 'black') }}>
                    	False {question.answers[1].isCorrect && '✓'}
                  	</p>
                	</>
              	)}
              	{question.questionType === 'ShortAnswer' && (
                	<>
                  	<p>Your answer: {userAnswers[qIndex]}</p>
                  	<p>Correct answer: {question.correctAnswer}</p>
                	</>
              	)}
              	{question.questionType === 'MultipleTextAnswer' && (
                	<>
                  	<p>Your answer: {userAnswers[qIndex]}</p>
                  	<p>Correct answers: {question.correctAnswers.join(', ')}</p>
                  	<p style={{ color: checkMultipleTextAnswer(userAnswers[qIndex], question.correctAnswers, question.caseSensitive) ? 'green' : 'red' }}>
											{checkMultipleTextAnswer(userAnswers[qIndex], question.correctAnswers, question.caseSensitive) ? 'Correct' : 'Incorrect'}
                  	</p>
                	</>
              	)}
            	</div>
          	))}
        	</div>
      	)}

    		{quizEnded && !localStorage.getItem('token') && (
  				<div>
    				<p>Create an account to save your score and track your progress!</p>
    				<Link to="/signup" className={styles.formButton}>Sign Up</Link>
  				</div>
				)}
      </div>
    </div>
  );
}

export default QuizDetail;
